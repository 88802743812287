<template>
  <div></div>
</template>
<script>
export default {
    name: "PasswordResetView",
    data() {
        return {
            password: '',
        }
    },
}
</script>
<style scoped>

</style>